<template>
  <v-select
    v-model="selectedLocation"
    :label="$trans('service_location')"
    :items="locations"
    item-value="id"
    return-object
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :deletable-chips="deletableChips"
    hide-details
    outlined
    clearable
    :menu-props="{ offsetY: true }"
    @change="updateChanges"
  >
    <template #selection="data">
      <div class="d-flex align-center pointer" style="max-width: 99%">
        <v-chip
          v-if="chips || smallChips"
          :disabled="disabled"
          :small="smallChips"
          :close="deletableChips"
          @click:close="removeChip(data.item)"
        >
          <v-icon
            v-if="getIconForType(data.item.type)"
            color="primary"
            size="20"
            class="mr-1 ml-n1"
          >
            {{ getIconForType(data.item.type) }}
          </v-icon>
          <img
            v-else
            height="15"
            :src="getImageForType(data.item.type)"
            :alt="$trans('service_location_' + data.item.type)"
            :class="{
              'mr-1 ml-n1': smallChips,
            }"
          />
          <span class="text-truncate">
            <template v-if="!isStaticRuleLocation(data.item.type)">
              ({{ $trans("id") }}: {{ data.item.id }})
            </template>
            <template v-if="data.item.name">{{ data.item.name }}</template>
            <template v-else>
              {{ $trans("service_location_" + data.item.type) }}
            </template>
            <template v-if="data.item.data"> ({{ data.item.data }})</template>
          </span>
        </v-chip>
        <template v-else>
          <span class="text-truncate">
            <template v-if="!isStaticRuleLocation(data.item.type)">
              ({{ $trans("id") }}: {{ data.item.id }})
            </template>
            <template v-if="data.item.name">{{ data.item.name }}</template>
            <template v-else>
              {{ $trans("service_location_" + data.item.type) }}
            </template>
            <template v-if="data.item.data"> ({{ data.item.data }})</template>
          </span>
        </template>
      </div>
    </template>

    <template #item="{ item, on, attrs }">
      <v-list-item
        v-bind="attrs"
        :selected="selectedLocation && item.id === selectedLocation.id"
        v-on="on"
      >
        <v-list-item-icon class="d-flex justify-start">
          <v-icon v-if="getIconForType(item.type)" color="primary">
            {{ getIconForType(item.type) }}
          </v-icon>
          <img
            v-else
            height="20"
            :src="getImageForType(item.type)"
            :alt="$trans('service_location_' + item.type)"
          />
        </v-list-item-icon>
        <v-list-item-title>
          <span class="text-truncate">
            <template v-if="!isStaticRuleLocation(item.type)">
              ({{ $trans("id") }}: {{ item.id }})
            </template>
            <template v-if="item.name">{{ item.name }}</template>
            <template v-else>
              {{ $trans("service_location_" + item.type) }}
            </template>
            <template v-if="item.data"> ({{ item.data }})</template>
          </span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-select>
</template>
<script>
import locationActions from "@/calendesk/mixins/locationActions";

export default {
  name: "LocationServiceSelectField",
  mixins: [locationActions],
  model: {
    prop: "selectedLocation",
    event: "change",
  },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedLocation: null,
    };
  },
  watch: {
    locations() {
      this.selectedLocation = null;
    },
  },
  methods: {
    updateChanges() {
      this.$emit("input", this.selectedLocation);
      this.$emit("change", this.selectedLocation);
    },
    removeChip() {
      this.selectedLocation = null;
      this.updateChanges();
    },
  },
};
</script>
