<template>
  <div class="d-flex align-center">
    <v-avatar
      class="mr-2"
      :size="large ? '48px' : medium ? '32px' : '24px'"
      left
    >
      <v-img :src="$helpers.getAvatarSrc(user)" />
    </v-avatar>
    <span
      :class="{
        'text-body-1 font-weight-medium': large,
      }"
      >{{ $helpers.getUserName(user, employee, hideId) }}</span
    >
  </div>
</template>

<script>
export default {
  name: "AvatarUserNames",
  props: {
    large: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
    hideId: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      required: true,
    },
    employee: {
      type: Object,
      default: null,
    },
  },
};
</script>
