import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import { mapActions, mapGetters } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";

export default {
  mixins: [sharedActions],
  computed: {
    ...mapGetters({
      getRefreshSchedulesListStatus:
        "availabilitySchedule/getRefreshSchedulesListStatus",
    }),
  },
  methods: {
    ...mapActions({
      fetchAllAvailabilitySchedules: "availabilitySchedule/fetchPaginated",
      deleteSchedule: "availabilitySchedule/delete",
      refreshSchedulesList: "availabilitySchedule/refreshSchedulesList",
      updateOwnEmployee: "employee/updateOwn",
      loadAuthUser: "setup/loadAuthUser",
    }),
    handleAssignToEmployees(item) {
      this.openDialog({
        type: dialogTypes.AVAILABLE_SCHEDULE_ASSIGN_TO_EMPLOYEES,
        data: item,
        persistent: true,
        size: dialogSize.MIDDLE,
        title: this.$trans("assign_to_employees"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
      });
    },
    handleAssignToServices(item) {
      this.openDialog({
        type: dialogTypes.AVAILABLE_SCHEDULE_ASSIGN_TO_SERVICES,
        data: item,
        persistent: true,
        size: dialogSize.MIDDLE,
        title: this.$trans("assign_to_services"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
      });
    },
    handleAssignToMyself(item) {
      this.updateOwnEmployee({
        availability_schedule_id: item.id,
      })
        .then(() => {
          this.loadAuthUser();
        })
        .finally(() => {
          this.refreshSchedulesList();
          this.fetchAllAvailabilitySchedules();
          this.setCommonDialogLoader(false);
          this.closeConfirmDialog();
        });
    },
    handleEditAvailability(item) {
      this.openDialog({
        type: dialogTypes.AVAILABILITY_SCHEDULE,
        data: item,
        persistent: true,
        size: dialogSize.FULL_SCREEN,
        title: this.$trans("update_availability_schedule"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$save",
        ctaButtonTitle: this.$trans("save"),
      });
    },
    handleDeleteAvailabilities(items) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.setCommonDialogLoader(true);

          const requests = [];
          items.forEach((schedule) => {
            requests.push(this.deleteSchedule(schedule.id));
          });

          Promise.all(requests)
            .then(() => {
              successNotification("delete_succeeded");
              this.$emit("close");
            })
            .catch((error) => {
              errorNotification(null, error, false);
            })
            .finally(() => {
              this.refreshSchedulesList();
              this.fetchAllAvailabilitySchedules();

              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
    handleAddAvailability() {
      this.openDialog({
        type: dialogTypes.AVAILABILITY_SCHEDULE,
        data: null,
        persistent: true,
        size: dialogSize.FULL_SCREEN,
        title: this.$trans("add_new_availability_schedule"),
        ctaButtonAction: "handleSave",
        ctaButtonIcon: "$plus-circle",
        ctaButtonTitle: this.$trans("add"),
      });
    },
  },
};
