<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <app-bar-route />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center flex-wrap"
      >
        <v-btn
          depressed
          outlined
          color="secondary"
          class="ma-1"
          @click="handleAddAvailability"
        >
          <v-icon left> $plus-circle</v-icon>
          <span>{{ $trans("add_availability_schedule") }}</span>
        </v-btn>
        <v-btn
          depressed
          outlined
          color="secondary"
          class="ma-1"
          @click="showServiceAvailability = true"
        >
          <v-icon left> $availability</v-icon>
          <span>{{ $trans("test_availability") }}</span>
        </v-btn>

        <service-availability-dialog
          v-model="showServiceAvailability"
          @close="showServiceAvailability = false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import availabilityActions from "@/calendesk/mixins/availabilityActions";
import ServiceAvailabilityDialog from "@/components/Forms/ServiceAvailabilityDialog.vue";

export default {
  name: "AppBarList",
  components: {
    ServiceAvailabilityDialog,
    AppBarRoute: () => import("../AppBarComponents/AppBarRoute"),
  },
  mixins: [availabilityActions],
  data() {
    return {
      showServiceAvailability: false,
    };
  },
};
</script>
