<template>
  <v-dialog
    v-model="modal"
    scrollable
    fullscreen
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card class="white" :loading="isLoading">
      <v-card-title>
        <v-btn fab x-small @click="closeDialog">
          <v-icon> $close </v-icon>
        </v-btn>
        <v-spacer />
      </v-card-title>
      <v-container>
        <v-row>
          <v-col :md="schedulesV2Enabled ? 3 : 4" cols="12">
            <div>
              <span class="text-h5 d-flex align-center">{{
                $trans("service_availability_title")
              }}</span>
              <small>{{ $trans("select_availability_service_info") }}</small>
            </div>
          </v-col>
          <v-col :md="schedulesV2Enabled ? 3 : 4" cols="12">
            <services-field
              v-model="selectedService"
              :clearable="false"
              :disabled="isLoading"
              chips
              deletable-chips
            />
          </v-col>
          <v-col v-if="schedulesV2Enabled" md="3" cols="12">
            <location-service-select-field
              v-model="selectedLocation"
              :locations="getAvailableLocations"
              :disabled="!selectedService"
              chips
              small-chips
              deletable-chips
            />
          </v-col>
          <v-col :md="schedulesV2Enabled ? 3 : 4" cols="12">
            <calendar-input
              v-model="selectedDate"
              :label="$trans('select_date')"
              :disabled="isLoading"
              :min="getToday()"
            />
          </v-col>
        </v-row>
        <v-row v-if="!selectedService" justify="center">
          <v-col md="6" cols="12">
            <calendesk-information-message
              color="orange"
              additional-class="white--text"
              icon-color="white"
              icon="$alert"
            >
              {{ $trans("select_service_error") }}
            </calendesk-information-message>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="slots" class="overflow-y-auto mt-4">
        <template v-for="item in slots">
          <v-row v-if="item.employee" :key="item.employee.id">
            <v-col cols="12">
              <avatar-user-names
                large
                :employee="item.employee"
                :user="item.employee.user"
              />
            </v-col>
            <v-col cols="12">
              <v-container>
                <v-row v-if="isLoading">
                  <v-col>
                    <v-skeleton-loader
                      v-for="number in 42"
                      :key="number"
                      type="button"
                      height="20"
                      class="ma-2 float-left"
                    />
                  </v-col>
                </v-row>
                <template v-else>
                  <v-row
                    v-for="(timeSlots, timeSlotsIndex) in item.slots"
                    :key="item.employee.id + timeSlotsIndex"
                    justify="start"
                  >
                    <v-col>
                      <template v-if="timeSlots && timeSlots.length > 0">
                        <div
                          v-for="(slot, slotIndex) in timeSlots"
                          :key="item.employee.id + timeSlotsIndex + slotIndex"
                          class="float-left ma-2"
                        >
                          <v-badge
                            v-if="isGroupService"
                            color="green"
                            overlap
                            bordered
                          >
                            <template #badge>
                              {{ selectedService.max_people - slot.used }}
                            </template>
                            <v-btn
                              :disabled="isLoading"
                              outlined
                              color="secondary"
                              @click="selectTimeSlot(slot.time, item.employee)"
                            >
                              {{ getSlotString(slot.time) }}
                            </v-btn>
                          </v-badge>
                          <v-btn
                            v-else
                            :disabled="isLoading"
                            outlined
                            color="secondary"
                            @click="selectTimeSlot(slot.time, item.employee)"
                          >
                            {{ getSlotString(slot.time) }}
                          </v-btn>
                        </div>
                      </template>
                      <template v-else>
                        <v-col
                          class="d-flex align-center justify-center text-body-1"
                        >
                          <span v-if="schedulesV2Enabled">{{
                            $trans("no_available_time_slots_v2")
                          }}</span>
                          <span v-else>
                            {{ $trans("no_available_time_slots") }}
                          </span>
                        </v-col>
                      </template>
                    </v-col>
                  </v-row>
                </template>
              </v-container>
            </v-col>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
        </template>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CalendarInput from "@/lib/calendesk-js-library/components/CalendarInput.vue";
import AvatarUserNames from "@/components/AvatarUserNames";
import ServicesField from "@/lib/calendesk-js-library/components/ServicesField";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import helpers, {
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";
import LocationServiceSelectField from "@/components/Forms/LocationServiceSelectField.vue";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ServiceAvailabilityDialog",
  components: {
    LocationServiceSelectField,
    CalendeskInformationMessage,
    ServicesField,
    AvatarUserNames,
    CalendarInput,
  },
  mixins: [sharedActions],
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    service: {
      type: [Object, null],
      default: null,
    },
    location: {
      type: [Object, null],
      default: null,
    },
    startDate: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      modal: this.value,
      isLoading: false,
      slots: null,
      selectedDate: this.$moment(this.startDate).isAfter(this.$moment())
        ? this.startDate
        : this.$moment().format(helpers.dateFormat),
      selectedService: this.service,
      selectedLocation: this.location,
    };
  },
  computed: {
    ...mapGetters({
      employees: "employee/getAllEmployees",
      adminConfiguration: "setup/getAdminConfiguration",
    }),
    getDuration() {
      return this.selectedService && this.selectedService.selectedType
        ? this.selectedService.selectedType.duration
        : this.selectedService.duration;
    },
    isGroupService() {
      return this.selectedService && this.selectedService.max_people > 1;
    },
    getAvailableLocations() {
      if (this.selectedService) {
        return this.selectedService.locations;
      }

      return [];
    },
    schedulesV2Enabled() {
      return !!(
        this.adminConfiguration && this.adminConfiguration.schedules_v2_enabled
      );
    },
  },
  watch: {
    value(val) {
      this.modal = val;
      this.reloadTimeSlots();
    },
    startDate(val) {
      this.selectedDate = val;
      this.reloadTimeSlots();
    },
    service(val) {
      this.selectedService = val;
      this.reloadTimeSlots();
    },
    location(val) {
      this.selectedLocation = val;
      this.reloadTimeSlots();
    },
    selectedService() {
      this.selectedLocation = null;
      this.reloadTimeSlots();
    },
    selectedDate() {
      this.reloadTimeSlots();
    },
    selectedLocation() {
      this.reloadTimeSlots();
    },
  },
  methods: {
    ...mapActions({
      fetchAvailableBookingSlots: "booking/fetchAvailableBookingSlots",
    }),
    getEmployeeById(employeeId) {
      if (this.employees) {
        return this.employees.find(
          (employee) => employee.id === parseInt(employeeId),
        );
      }

      return null;
    },
    closeDialog() {
      this.$emit("close");
    },
    reloadTimeSlots() {
      if (this.modal && this.selectedService && this.selectedDate) {
        this.isLoading = true;

        const requestData = {
          used_slots: 1,
          number_of_days: 1,
          service_id: this.selectedService.id,
          start_date: this.selectedDate,
          service_type_id: this.selectedService.selectedType
            ? this.selectedService.selectedType.id
            : null,
          customer_time_zone: userTimeZone(),
        };

        if (this.schedulesV2Enabled && this.selectedLocation) {
          requestData.location_id = this.selectedLocation.location_id;
        }

        this.fetchAvailableBookingSlots(requestData)
          .then((response) => {
            this.slots = this.parseSlots(response);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    parseSlots(slots) {
      const result = [];
      for (const [key, value] of Object.entries(slots)) {
        const employee = this.getEmployeeById(key);

        if (employee) {
          result.push({
            employee: this.getEmployeeById(key),
            slots: value,
          });
        }
      }

      return result;
    },
    getSlotString(startTime) {
      if (this.getDuration <= 1435) {
        return `${startTime} - ${this.getEndTime(startTime)}`;
      }

      return startTime;
    },
    getEndTime(startTime) {
      return this.$moment(`${this.selectedDate} ${startTime}`)
        .add(this.getDuration, "minutes")
        .format(this.$helpers.timeFormat);
    },
    selectTimeSlot(timeSlot, employee) {
      this.$emit("update", {
        startDate: this.selectedDate,
        startTime: timeSlot,
        endDate: this.selectedDate,
        endTime: this.getEndTime(timeSlot),
        service: this.selectedService,
        employee,
      });

      this.closeDialog();
    },
  },
};
</script>
